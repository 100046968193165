<template>
    <b-card>
        <validation-observer ref="expenseRules">
            <b-form @submit.prevent>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                        label="Item Name"
                        label-for="item-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Item Name"
                                rules="required"
                            >
                                <b-form-input
                                    id="item-name"
                                    v-model="expense.itemName"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Item Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group
                        label="Account Name"
                        label-for="account-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Account Name"
                                rules="required"
                            >
                                <v-select
                                    id="account-name"
                                    v-model="expense.accountName"
                                    :options="accountsList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Select Account/Client Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group
                        label="Invoice No"
                        label-for="invoice-no"
                        >
                            <!-- <validation-provider
                                #default="{ errors }"
                                name="Invoice No"
                                rules="required"
                            > -->
                                <b-form-input
                                    id="invoice-no"
                                    v-model="expense.invoiceNo"
                                    placeholder="Invoice No"
                                />
                            <!-- </validation-provider>     -->
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Business Unit"
                        label-for="business-unit"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Business Unit"
                                rules="required"
                            >
                                <v-select
                                    id="business-unit"
                                    placeholder="Select Business Unit"
                                    v-model="expense.businessUnit"
                                    :options="businessUnitList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="expense.businessUnit === 'Infra'">
                        <b-form-group
                        label="Project"
                        label-for="project"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Project"
                                rules="required"
                            >
                                <v-select
                                    id="project"
                                    placeholder="Select or Add Project"
                                    v-model="expense.project"
                                    :options="expenseProjectList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                    taggable
                                    push-tags
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Category"
                        label-for="category"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Category"
                                rules="required"
                            >
                                <v-select
                                    id="category"
                                    placeholder="Select Category"
                                    v-model="expense.category"
                                    :options="expenseCategoryList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="filteredSubCategories.length > 0">
                        <b-form-group
                        label="Sub-Category"
                        label-for="sub-category"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Sub-Category"
                                rules="required"
                            >
                                <v-select
                                    id="sub-category"
                                    placeholder="Select Sub-Category"
                                    v-model="expense.subCategory"
                                    :options="filteredSubCategories"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Payment Mode"
                        label-for="payment-mode"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Payment Mode"
                                rules="required"
                            >
                                <v-select
                                    id="payment-mode"
                                    placeholder="Select Payment Mode"
                                    v-model="expense.paymentMode"
                                    :options="paymentModeList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                    multiple
                                    :close-on-select="false"
                                    taggable
                                    push-tags
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Expense Date"
                        label-for="expense-date"
                        >
                            <!-- <validation-provider
                                #default="{ errors }"
                                name="Expense Date"
                                rules="required"
                            > -->
                                <flat-pickr
                                    class="form-control"
                                    id="expense-date" 
                                    v-model="expense.expenseDate" 
                                    placeholder="YYYY-MM-DD"
                                    
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> 
                                :config="{ enableTime: false,dateFormat: 'd M Y'}"-->
                            <!-- </validation-provider>     -->
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Expense Amount"
                        label-for="expense-amount"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Expense Amount"
                                rules="required"
                            >
                                <b-form-input
                                    id="expense-amount"
                                    v-model="expense.amount"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Expense Amount"
                                    type="number"
                                    step=".01"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Expense Due Date"
                        label-for="expense-due-date"
                        >
                            
                                <flat-pickr
                                    class="form-control"
                                    id="expense-due-date" 
                                    v-model="expense.dueDate" 
                                    placeholder="YYYY-MM-DD"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> 
                                :config="{ enableTime: false,dateFormat: 'Y M d'}"-->
                            <!-- </validation-provider>     -->
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="4">
                        <b-form-group
                        label="GST Invoice"
                        label-for="is-gst"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="GST Invoice"
                                rules="required"
                            >
                                <v-select
                                    id="is-gst"
                                    placeholder="GST YES/NO"
                                    v-model="expense.isGSTInvoice"
                                    :options="booleanList"
                                    label="title"
                                    :reduce="(option) => option.value"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="expense.isGSTInvoice">
                        <b-form-group
                        label="GST Amount"
                        label-for="gst-amount"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="GST Amount"
                                rules="required"
                            >
                                <b-form-input
                                    id="gst-amount"
                                    v-model="expense.gstAmount"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="GST Amount"
                                    type="number"
                                    step=".01"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="TDS Deduction"
                        label-for="is-tds"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="TDS Deduction"
                                rules="required"
                            >
                                <v-select
                                    id="is-tds"
                                    placeholder="TDS Deduction YES/NO"
                                    v-model="expense.isTDSDeduction"
                                    :options="booleanList"
                                    label="title"
                                    :reduce="(option) => option.value"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="expense.isTDSDeduction">
                        <b-form-group
                        label="TDS Deduction Amount"
                        label-for="tds-amount"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="TDS Amount"
                                rules="required"
                            >
                                <b-form-input
                                    id="tds-amount"
                                    v-model="expense.tdsAmount"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="TDS Amount"
                                    type="number"
                                    step=".01"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                        label="Location"
                        label-for="location"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Location"
                                rules="required"
                            >
                                <v-select
                                    id="location"
                                    placeholder="Select Location"
                                    v-model="expense.location"
                                    :options="locationList"
                                    label="title"
                                    :reduce="(option) => option.title"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>    
                        </b-form-group>
                    </b-col>
                    
                    <b-col md="8">
                        <b-form-group
                        label-for="file"
                        label="Expense File"
                        >
                            <b-form-file
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                id="cv-file"
                                name="cv-file"
                                accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/rtf, text/markdown, image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                @change="openFile"
                                v-model="file"
                                type="file"
                                ref="file"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                        label-for="comment"
                        label="Add Comment For Approval"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Comment"
                                rules="required"
                            >
                                <b-form-textarea
                                    id="expense-comment"
                                    placeholder="Add Comment For Approval"
                                    v-model="expense.comment"
                                    :state="errors.length > 0 ? false:null"
                                />
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    

                <!-- submit and reset -->
                <b-col>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="submitForm"
                    >
                        Submit
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="reset"
                    >
                        Reset
                    </b-button>
                </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>
<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    BTable,
    BFormDatepicker
  } from "bootstrap-vue";
  
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import clientService from '@/services/client-service'
    import locationService from '@/services/location-service'
    import AdminConfigService from '@/services/admin-config-service'
    import Ripple from 'vue-ripple-directive'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import vSelect from "vue-select";
    import flatPickr from 'vue-flatpickr-component';
    import { required,double } from '@validations'
    import expenseService from '@/services/expense-service'
  
    export default {
      components: {
          BButton,
          BForm,
          BFormGroup,
          BCard,
          BRow,
          BCol,
          BFormTextarea,
          BTable,
          BFormInput,
          BFormFile,
          // Form Validation
          ValidationProvider,
          ValidationObserver,
          BFormDatepicker,
          vSelect,
          flatPickr
      },
      data() {
        return {
          expense : {
            itemName: null,
            accountName: null,
            businessUnit: null,
            project:  null,
            invoiceNo: null,
            category: null,
            subCategory: null,
            expenseDate: null,
            paymentMode: null,
            isGSTInvoice: null,
            isTDSDeduction: null,
            dueDate: null,
            amount: 0,
            gstAmount: 0,
            tdsAmount: 0,
            location: null,
            paymentDueDate: null,
            comment: null,
            isApproved: false,
            isAutoApproved: false,
            updatedBy: null,
            addedBy: null,
            approvedBy: null,
            file: null
          },
          accountsList: [],
          businessUnitList: [],
          expenseCategoryList: [],
          expenseSubCategoryList: [],
          paymentModeList: [],
          locationList: [],
          booleanList: [
            { title: "YES", value: true},
            { title: "NO", value: false}
          ],
          file: null,
          validatedForm: false,
          expenseProjectList: []
  
        };
      },
      directives: {
        Ripple,
      },
      computed: {
        filteredSubCategories() {
            if (!this.expense.category) return [];

            const prefix = `SUB-CATEGORY - ${this.expense.category}`;
            console.log('sub category filter: ',this.expenseSubCategoryList.filter(subCat => subCat.param_name === prefix))
            return this.expenseSubCategoryList.filter(subCat => subCat.param_name === prefix);
        }
      },
      created() {
        // Fetch questions and user stats on component creation
        this.invoiceId = this.$route.params.id
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.empId = userData.emp_id
        // this.role = userData.roles[0].toLowerCase()
        this.name = userData.first_name + ' ' + userData.last_name
        this.expense.updatedBy = {
            title: this.name,
            empId: this.empId,
            email: userData.email
        }
        this.expense.approvedBy = {
            title: this.name,
            empId: this.empId,
            email: userData.email
        }
        
      },
      methods: {
        validationForm() {
            this.$refs.expenseRules.validate().then(success => {
                if (success) {
                // eslint-disable-next-line
                    this.validatedForm = true
                } else {
                    alert('Form validation Failed !!!')
                }
            })
        },
        submitForm() {
            const formData = new FormData();

            // Assuming `this.expense` is your data model in the Vue component
            formData.append('item', this.expense.itemName);
            formData.append('account', this.expense.accountName);
            formData.append('invoice_no', this.expense.invoiceNo);
            formData.append('business_unit', this.expense.businessUnit);
            formData.append('project', this.expense.project);
            formData.append('category', this.expense.category);
            formData.append('sub_category', this.expense.subCategory);
            formData.append('expense_date', this.expense.expenseDate);
            formData.append('amount', this.expense.amount);
            formData.append('payment_mode', this.expense.paymentMode);
            formData.append('is_gst', this.expense.isGSTInvoice);
            formData.append('gst_amount', this.expense.gstAmount);
            formData.append('is_tds', this.expense.isTDSDeduction);
            formData.append('tds_amount', this.expense.tdsAmount);
            if(this.expense.dueDate) {
                formData.append('due_date', this.expense.dueDate);
            }
            formData.append('location', this.expense.location);
            formData.append('comment', this.expense.comment);
            formData.append('updated_by', JSON.stringify(this.expense.updatedBy));
            //formData.append('updated_by', this.empId);
            //formData.append('is_approved', false);
            formData.append('is_auto_approved', false);

            // For file, assuming you're handling file input change with something like this:
            // <input type="file" @change="handleFileUpload">
            // And your method looks like this:
            // handleFileUpload(event) {
            //   this.expense.file = event.target.files[0];
            // }
            if (this.expense.file) {
                formData.append('expenseFile', this.expense.file);
            }
            console.log('EXPENSE: ', this.expense)
            expenseService.editExpense(this.expense.id,formData).then(res =>{
                if(res.status === "OK") {
                    console.log('Expense Updated Successfully', res.data)
                    this.loading = false
                    //this.getReport()
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Updated Successfully & sent for Approval!!!',
                        icon: 'EditIcon',
                        variant: 'primary',
                        timeout: 10000,
                      },
                    })
                    this.$router.push({ name: "apps-expense-tracker" });
                } else if ((res.status === 401 ) || (res.status === 403 )) {
                  window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                  //this.reportStatus = 'Session Expired... Redirecting to Login Page'
                  localStorage.clear();
                  this.$router.push({ name: "auth-login" });
                } else if ((res.status === 402 ) ) {
                  this.submitError = 'Failed! Some Error Occurred.. Please Contact Admin!!!'
                  //this.reportStatus = 'Invoice Id already exist, please use correct invoice id'
                } else if ((res.status === 400 ) ) {
                  //this.reportStatus = 'No Data Found for Selected Dates'
                  this.submitError = 'Failed! Some Error Occurred.. Please Contact Admin!!!'
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Update Failed !!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 5000,
                      },
                    })
                } else if ((res.status === 500 ) ) {
                  //this.reportStatus = 'No Data Found for Selected Dates'
                  this.submitError = 'Failed! Some Error Occurred.. Please Contact Admin!!!'
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Update Failed !!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 5000,
                      },
                    })
                }
            })
        },
        
        openFile(event) {
            this.file = event.target.files[0];
            console.log("file", this.file);
            console.log("cfile", this.expense.file)
        },
        reset() {
            this.expense = {
                itemName: null,
                accountName: null,
                businessUnit: null,
                project: null,
                invoiceNo: null,
                category: null,
                subCategory: null,
                expenseDate: null,
                paymentMode: null,
                isGSTInvoice: null,
                isTDSDeduction: null,
                dueDate: null,
                amount: null,
                gstAmount: null,
                tdsAmount: null,
                location: null,
                paymentDueDate: null,
                comment: null,
                isApproved: false,
                isAutoApproved: false,
                file: null
          }
        }
      },
      mounted() {
        clientService.getClients().then(res => {
            this.accountsList = res.data
            console.log(this.accountsList)
        })
        AdminConfigService.getConfigExpense().then(res => {
            if (res.status === 'OK'){
                console.log('expense config list: ', res.data)
                this.businessUnitList = res.data.businessUnit;
                this.expenseCategoryList = res.data.category;
                this.expenseSubCategoryList = res.data.sub_category;
                this.paymentModeList = res.data.payment_mode
            }
        })
        locationService.getAll().then(res => {
            console.log(res.data)
            this.locationList = res.data
        })
        expenseService.getExpenseProjectList().then(res => {
            console.log(res)
            if(res.status === 200) {
                this.expenseProjectList = res.data.data
            }
        })
        expenseService.getExpenseByID(this.$route.params.id).then(res => {
            if(res.status === "OK") {
                const expenseData = res.data
                this.expense = {
                    id: expenseData.id,
                    itemName: expenseData.item,
                    accountName: expenseData.account,
                    businessUnit: expenseData.business_unit,
                    project: expenseData.project,
                    invoiceNo: expenseData.invoice_no,
                    category: expenseData.category,
                    subCategory: expenseData.sub_category === "null" ? null : expenseData.sub_category, // Convert "null" string to actual null if necessary
                    expenseDate: expenseData.expense_date,
                    paymentMode: expenseData.payment_mode,
                    isGSTInvoice: expenseData.is_gst,
                    isTDSDeduction: expenseData.is_tds,
                    dueDate: expenseData.due_date,
                    amount: expenseData.amount,
                    gstAmount: expenseData.gst_amount,
                    tdsAmount: expenseData.tds_amount,
                    location: expenseData.location,
                    paymentDueDate: expenseData.due_date, // Assuming paymentDueDate maps to due_date in your data
                    comment: expenseData.comment,
                    isApproved: expenseData.is_approved,
                    isAutoApproved: expenseData.is_auto_approved,
                    file: expenseData.file_path, // Assuming this should point to the file path for consistency
                    added_by: expenseData.added_by,
                    updated_by: expenseData.updated_by,
                    approved_by: expenseData.approved_by

                };
            }
        })
      }
      
    };
    </script>
    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import '@core/scss/vue/libs/quill.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    </style>